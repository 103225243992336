import * as React from "react";
import { Helmet } from "react-helmet";

import Theme from "../components/theme";
import Footer from "../components/footer";

// import hero from '../images/hero.jpg';

import "../styles/index.css";
import Hero from "../components/hero";
import CookieConsent from "../components/cookieConsent";
import Buchung from "../components/buchung";
import Header from "../components/header";

const UeberMichPage = () => {
  return (
    <Theme>
      <Helmet>
        <title>Angela Hollstein - Über mich</title>
        <meta
          name="description"
          content="Supervision und Coaching in Freiburg im Breisgau und Umgebung"
        />
        <meta
          name="keywords"
          content="supervision, coaching, freiburg, schweiz, deutschland, beratung"
        />
      </Helmet>

      <div className="p-6">
        <Header />
      </div>

      {/* Über mich */}

      <div className="flex justify-center ">
        <div className="max-w-5xl bg-white bg-opacity-70 rounded-lg p-10">
          <div className="font-bold mb-12">
            Meine Leidenschaft ist Beratung und Unterstützung, sowie das
            gemeinsame Entwickeln von neuen Möglichkeiten und individuellen
            Lösungen für persönliche und berufliche Herausforderungen.
          </div>

          <h2 className="font-bold text-2xl">Profil</h2>

          <p className="text-lg underline mt-3">Ausbildung</p>
          <ul className="list-disc mt-2 list-inside">
            <li>Sozialmanagerin M.A.</li>
            <li>Diplom Sozialarbeiterin</li>
          </ul>

          <p className="text-lg underline mt-3">Qualifikationen</p>
          <ul className="list-disc mt-2 list-inside">
            <li>
              Systemische Supervisorin und Coach (DGSF-zertifiziert / Tandem Institut & Praxis)
            </li>
            <li>Kinderschutzfachkraft nach Lüttringhaus</li>
            <li>Heilpraktikerin für Psychotherapie</li>
            <li>Systemische Beratung (FFAK)</li>
          </ul>

          <p className="text-lg underline mt-3">Berufliche Stationen</p>
          <ul className="list-disc mt-2 list-inside">
            <li>Schulsozialarbeit</li>
            <li>Allgemeiner Sozialer Dienst</li>
            <li>Pflege- und Adoptivkinderdienst</li>
            <li>Frühe Hilfen</li>
            <li>Insoweit erfahrene Fachkraft</li>
            <li>Fachbereichsleiterin Soziale Fachdienste im Jugendamt</li>
            <li>Referentin im Bereich Kinderschutz, Frühe Hilfen</li>
            <li>Lehrbeauftragte an der evangelischen Hochschule Freiburg</li>
          </ul>
        </div>
      </div>

      <Buchung />

      <Footer />
      <CookieConsent />
    </Theme>
  );
};

export default UeberMichPage;
